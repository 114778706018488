<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Dotazníky - Přidat</strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <a-form :form="form" @submit="handleSubmit">
        <h4 class="text-black mt-4 mb-3"><strong>Nastavitelné podle jazyka</strong></h4>
        <a-form-item
          label="Otázka"
          :validate-status="error('sle_question') ? 'error' : ''"
          :help="error('sle_question') || ''"
        >
          <a-input placeholder="Otázka" v-decorator="['sle_question', {rules: [{max: 80, message: 'Otázka nemůže být delší než 80 znaků'}, {required: true, message: 'Otázka musí být vyplněn!'}]}]"/>
        </a-form-item>

        <a-form-item
          v-for="(k, index) in form.getFieldValue('items')"
          :key="index"
          :label="index === 0 ? 'Odpovědi' : ''"
          :required="true"
        >
          <a-row>
            <a-col :offset="2" :span="18">
              <a-form-item
                label=""
                :validate-status="error(`sae_answer[${index}]`) ? 'error' : ''"
                :help="error(`sae_answer[${index}]`) || ''"
              >
                <a-input v-decorator="[`sae_answer[${index}]`, { validateTrigger: ['change', 'blur'], rules: [{ required: true, message: 'Odpověď musí být vyplněna!' }]}]"
                         placeholder="Odpověď"
                />
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-icon
                v-if="form.getFieldValue('items').length > 1"
                class="dynamic-delete-button"
                type="minus-circle-o"
                :disabled="form.getFieldValue('items').length === 1"
                @click="() => removeItem(index)"
              />
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item v-bind="formItemLayout">
          <a-button
            class="m-auto d-block"
            type="dashed"
            @click="addItem"
            style="width: 200px;"
          >
            <a-icon type="plus" /> Přidat další
          </a-button>
        </a-form-item>

        <h4 class="text-black mt-4 mb-3"><strong>Ostatní</strong></h4>

        <a-form-item
          label="Uživatel musí zvolit minimálně X odpovědí"
          :validate-status="error('svy_selectable_min') ? 'error' : ''"
          :help="error('svy_selectable_min') || ''"
        >
          <a-input-number :step="1" :min="1" :max="9" v-decorator="[`svy_selectable_min`, { initialValue: 1, rules: [{ validator: validateToMax }, { required: true, message: 'Uživatel musí zvolit minimálně X odpovědí musí být vyplněna!'}]}]"
          >
          </a-input-number>
        </a-form-item>

        <a-form-item label="Uživatel muůže zvolit maximálně X odpovědí"
                     :validate-status="error('svy_selectable_max') ? 'error' : ''"
                     :help="error('svy_selectable_max') || ''">
          <a-input-number
            :step="1" :min="1" :max="9" v-decorator="[`svy_selectable_max`, { initialValue: 1, rules: [{ validator: validateToMin }, { required: true, message: 'Uživatel musí zvolit maximálně X odpovědí musí být vyplněna!'}]}]"
          >
          </a-input-number>
        </a-form-item>

        <a-form-item
          label="Zveřejnit do"
          :validate-status="error('svy_date_show_until') ? 'error' : ''"
          :help="error('svy_date_show_until') || ''"
        >
          <a-date-picker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            placeholder="Zveřejnit do"
            :disabledDate="disabledDate"
            v-decorator="[`svy_date_show_until`, { rules: [{ required: true, message: 'Zveřejnit do musí být vyplněna!'}]}]"
          ></a-date-picker>
        </a-form-item>

        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">Přidat</a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'
import moment from 'moment'
import { hasErrors } from '../../../services/forms'

export default {
  components: { ActionTools, LanguageTab },
  beforeCreate() {
    this.form = this.$form.createForm(this)
    this.form.getFieldDecorator('items', { initialValue: [{ sae_answer: undefined }], preserve: true })
    this.form.getFieldDecorator('sae_answer[0]', { initialValue: undefined, preserve: true })
  },
  data: function () {
    return {
      hasErrors,
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/survey',
          title: 'Seznam',
        },
      ],
      item: {
        languages: [],
        answers: [],
      },
      formItemLayout: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      },
    }
  },
  computed: {
    language: function () {
      return this.$store.getters['language/active']
    },
  },
  methods: {
    removeItem(index) {
      const items = this.form.getFieldValue('items')
      if (items.length === 1) {
        return
      }
      const answers = this.form.getFieldValue('sae_answer')
      items.splice(index, 1)
      answers.splice(index, 1)
      this.form.setFieldsValue({
        items: items,
        sae_answer: answers,
      })
    },
    addItem() {
      const newIndex = this.form.getFieldValue('items').length
      this.form.getFieldDecorator(`sae_answer[${newIndex}]`)
      this.form.setFields({
        items: { value: this.form.getFieldValue('items').concat({ sae_answer: undefined }) },
        ['sae_answer[' + newIndex + ']']: { value: undefined, errors: ['Odpověď musí být vyplněna!'] },
      })
      this.form.validateFields()
    },
    validateToMin(rule, value, callback) {
      if (value && parseInt(value) < parseInt(this.form.getFieldValue('svy_selectable_min'))) {
        callback(new Error('Max musí být větší nebo rovno min!'))
      } else {
        this.form.validateFields(['svy_selectable_min'])
        callback()
      }
    },
    validateToMax(rule, value, callback) {
      if (value && parseInt(value) > parseInt(this.form.getFieldValue('svy_selectable_max'))) {
        callback(new Error('Min musí být menší nebo rovno max!'))
      } else {
        this.form.validateFields(['svy_selectable_max'])
        callback()
      }
    },
    getData() {
      return {
        svy_selectable_min: this.form.getFieldValue('svy_selectable_min'),
        svy_selectable_max: this.form.getFieldValue('svy_selectable_max'),
        svy_date_show_until: this.toOutputDate(this.form.getFieldValue('svy_date_show_until')),
        languages: this.item.languages.filter(x => x.sle_question !== undefined && x.sle_question.trim().length > 0),
        answers: {
          count: this.item.answers.length > 0 ? this.item.answers[0].items.length : 0,
          languages: this.item.answers.map(y => {
            return {
              lge_id: y.lge_id,
              items: y.items.filter(x => x.sae_answer !== undefined && x.sae_answer.trim().length > 0),
            }
          }),
        },
      }
    },
    toOutputDate: function (text) {
      return this.$options.filters.formatOutputDate(text)
    },
    handleSubmit(e) {
      e.preventDefault()
      this.handleChange(this.language)
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('survey/post', this.getData())
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    handleChange(oldLanguage) {
      const foundObj = { item: false, answers: false }
      const newObj = {
        item: {
          lge_id: oldLanguage,
          sle_question: this.form.getFieldValue('sle_question'),
        },
        sae_answer: {
          lge_id: oldLanguage,
          items: this.form.getFieldValue('items').map((x, y) => { return { sae_answer: this.form.getFieldValue('sae_answer[' + y + ']') } }),
        },
      }
      this.item.languages.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.languages[key] = newObj.item
          foundObj.item = true
          return true
        }
      })
      this.item.answers.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.answers[key] = newObj.sae_answer
          foundObj.answers = true
          return true
        }
      })
      if (!foundObj.item) {
        this.item.languages.push(newObj.item)
      }
      if (!foundObj.answers) {
        this.item.answers.push(newObj.sae_answer)
      }
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    disabledDate(current) {
      return current && current < moment().endOf('day')
    },
  },
  watch: {
    language(newValue, oldValue) {
      if (oldValue !== null) {
        this.handleChange(oldValue)
      }
      if (newValue !== null) {
        const foundObj = { items: this.item.languages.find(x => x.lge_id === newValue), answers: this.item.answers.find(x => x.lge_id === newValue) }
        this.form.setFieldsValue({
          sle_question: foundObj.items === undefined ? '' : foundObj.items.sle_question,
        })
        if (foundObj.answers !== undefined) {
          foundObj.answers.items.forEach((value, key) => {
            this.form.getFieldDecorator(`sae_answer[${key}]`)
            this.form.setFields({
              ['sae_answer[' + key + ']']: {
                value: value.sae_answer,
                errors: value.sae_answer === undefined || value.sae_answer.trim().length === 0 ? ['Odpověď musí být vyplněna!'] : undefined,
              },
            })
          })
        } else {
          this.form.getFieldValue('items').forEach((value, key) => {
            this.form.getFieldDecorator(`sae_answer[${key}]`)
            this.form.setFields({
              ['sae_answer[' + key + ']']: {
                value: undefined,
                errors: ['Odpověď musí být vyplněna!'],
              },
            })
          })
        }
        this.form.validateFields()
      }
    },
  },
}
</script>
